import React from "react";
import clsx from "clsx";

interface Props {
	children: React.ReactNode;
	className?: string;
	noPadding?: boolean;
	compactPadding?: boolean;
	as?: React.ElementType;
	backgroundColor?: string;
	backgroundImage?: string;
	backgroundPosition?: string;
	id?: string;
	roundedCorners?: string;
}

export function Container({
	children,
	className,
	noPadding = false,
	compactPadding = false,
	as,
	backgroundColor,
	backgroundImage,
	id,
	roundedCorners,
	backgroundPosition,
}: Props) {
	const Tag = as ?? "div";

	return (
		<Tag
			id={id}
			className={clsx(
				{ "bg-cover bg-no-repeat": backgroundImage },
				"mx-auto max-w-container-with-padding",
				{
					"px-layout2 py-layout3 md:py-layout6": !noPadding && !compactPadding,
				},
				{
					"p-layout2": compactPadding,
				},
				roundedCorners,
				className
			)}
			style={{
				backgroundColor: backgroundColor,
				backgroundImage: backgroundImage,
				backgroundPosition: backgroundPosition,
			}}
		>
			{children}
		</Tag>
	);
}
